import React from 'react';
import { CTA } from 'components/cta/CTA';
import { RichText } from 'components/rich-text/RichText';

export const CtaSlice = ({ title, tagline, text, label, link, graphic, isSlice }) => {
  return (
    <CTA
      tagline={tagline}
      title={title}
      text={RichText.render(text)}
      label={label}
      link={link}
      globe={graphic === '3D Globe'}
      graphic={graphic}
      isSlice={isSlice}
    />
  );
};

export default CtaSlice;
